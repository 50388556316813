import React, { Fragment } from "react";
import { Link } from "gatsby";
import GatsbyImages from "../../GatsbyImages";

function Block6({ data, islink, datalink }) {
  // each flag element
  const flagsElements = data.country.map(({ flag, url, title }, index) => {
    let link;
    const isInterNalLink = url.includes("https://www.sendity.com/");

    if (isInterNalLink) {
      let targetUrl = url.split("https://www.sendity.com")[1];

      link = (
        <Link to={targetUrl} className="mapLink">
          <GatsbyImages
            src={flag.localFile.childImageSharp.gatsbyImageData}
            ext={flag.ext}
            alt={flag.alternativeText}
          />

          <span>{title}</span>
        </Link>
      );
    }
    if (!isInterNalLink) {
      link = (
        <a href={url} className="mapLink">
          <GatsbyImages
            src={flag.localFile.childImageSharp.gatsbyImageData}
            ext={flag.ext}
            alt={flag.alternativeText}
          />

          <span>{title}</span>
        </a>
      );
    }

    return (
      <Fragment key={index}>
        {!islink && (
          <li>
            <div className="mapLink out">
              <GatsbyImages
                src={flag.localFile.childImageSharp.gatsbyImageData}
                ext={flag.ext}
                alt={flag.alternativeText}
              />
              <span>{title}</span>
            </div>
          </li>
        )}

        {islink && <li>{link}</li>}
      </Fragment>
    );
  });

  const linkExist =
    datalink &&
    !Object.values(datalink).some(
      (element) => element === null || element === "",
    );

  const linkButtomELement = linkExist ? (
    <div className="store  buttoncenter">
      {/* not defined where does this go to */}
      <a href={datalink.link}>{datalink.text}</a>
    </div>
  ) : null;

  return (
    <div className="landings">
      <div className="container">
        <h2>{data.title}</h2>

        <ul className="clearfix  flex-wrap">{flagsElements}</ul>
        {linkButtomELement}
      </div>
    </div>
  );
}

export { Block6 };
